//
// Typography
//

// Font Families
$g-font-primary: "Open Sans", Helvetica, Arial, sans-serif !default;
$g-font-secondary: "Roboto Slab", Helvetica, Arial, sans-serif !default;
$g-font-code: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$g-font-niconne: "Niconne", cursive !default;

// Font Sizes
$g-font-primary-size: 14px !default;



//
// Breakpoints
//

$g-2xs: 446px !default; // Double extra small devices (<=445px)
// Extra small devices (portrait phones, <=575px)
$g-sm: 576px !default; // Small devices (landscape phones, >=576 & <=767)
$g-md: 768px !default; // Medium devices (tablets, >=768 & <=991)
$g-lg: 992px !default; // Large devices (desktops, >=992 & <=1199)
$g-xl: 1200px !default; // Extra large devices (large desktops, >=1200)
// If you want to use "max-width" than var-1. For exmaple: @media (max-width: #{$g-lg}-1) {...}



//
// Colors
//

// Main Colors
$g-color-main: #555 !default;
$g-bg-color-main: #fff !default;

// Primary Color
$g-color-primary: #0054a6 !default;
$g-color-primary-dark-v1: darken($g-color-primary, 2%) !default;
$g-color-primary-dark-v2: darken($g-color-primary, 5%) !default;
$g-color-primary-dark-v3: darken($g-color-primary, 10%) !default;
$g-color-primary-light-v1: rgba($g-color-primary, .2) !default;
$g-color-primary-light-v2: rgba($g-color-primary, .8) !default; // R

// Secondary Color
$g-color-secondary: #e74b3c !default;
$g-bg-color-secondary: #fafafa !default;

// Color Black
$g-color-black: #000 !default;

// Color White
$g-color-white: #fff !default;

// Color Gray
$g-color-gray-light-v1: #bbb !default;
$g-color-gray-light-v2: #ccc !default;
$g-color-gray-light-v3: #ddd !default;
$g-color-gray-light-v4: #eee !default;
$g-color-gray-light-v5: #f7f7f7 !default;

$g-color-gray-dark-v1: #111 !default;
$g-color-gray-dark-v2: #333 !default;
$g-color-gray-dark-v3: #555 !default;
$g-color-gray-dark-v4: #777 !default;
$g-color-gray-dark-v5: #999 !default;


//
// Status Colors

// Color Success
$g-color-success:             #5cb85c !default;
$g-color-success-dark-v1:     darken($g-color-success, 2%) !default;
$g-color-success-dark-v2:     darken($g-color-success, 5%) !default;

// Color Info
$g-color-info:                #5bc0de !default;
$g-color-info-dark-v1:        darken($g-color-info, 2%) !default;
$g-color-info-dark-v2:        darken($g-color-info, 5%) !default;

// Color Warning
$g-color-warning:             #f0ad4e !default;
$g-color-warning-dark-v1:     darken($g-color-warning, 2%) !default;
$g-color-warning-dark-v2:     darken($g-color-warning, 5%) !default;

// Color Danger
$g-color-danger:              #d9534f !default;
$g-color-danger-dark-v1:      darken($g-color-danger, 2%) !default;
$g-color-danger-dark-v2:      darken($g-color-danger, 5%) !default;


//
// Complementary Colors

// Color Green
$g-color-green: #72c02c !default;

// Color Blue
$g-color-blue: #3398dc !default;
$g-color-blue-dark-v1: darken($g-color-blue, 22%) !default;

// Color Light Blue
$g-color-lightblue: #edf2f8 !default;
$g-color-lightblue-v1: #d6e2ee !default;

// Color Dark Blue
$g-color-darkblue: #009 !default;

// Color Indigo
$g-color-indigo: #4263a3 !default;

// Color Red
$g-color-red: #f00 !default;

// Color Light Red
$g-color-lightred: #e64b3b !default;

// Color Dark Red
$g-color-darkred: #a10f2b !default;

// Color Purple
$g-color-purple: #9a69cb !default;

// Color Dark Purple
$g-color-darkpurple: #6639b6 !default;
$g-color-purple-dark-v1: darken($g-color-purple, 27%) !default;

// Color Pink
$g-color-pink: #e81c62 !default;
$g-color-pink-dark-v1: darken($g-color-pink, 27%) !default;

// Color Orange
$g-color-orange: #e57d20 !default;

// Color Deep Orange
$g-color-deeporange: #fe541e !default;

// Color Yellow
$g-color-yellow: #ebc71d !default;

// Color Aqua
$g-color-aqua: #29d6e6 !default;
$g-color-aqua-dark-v1: darken($g-color-aqua, 22%) !default;

// Color Cyan
$g-color-cyan: #00bed6 !default;

// Color Teal
$g-color-teal: #18ba9b !default;

// Color Brown
$g-color-brown: #9c8061 !default;

// Color Blue Gray
$g-color-bluegray: #585f69 !default;

// Color Beige
$g-color-beige: #e5e1de !default;


//
// Social Colors

// Facebook Color
$g-color-facebook: #3b5998 !default;
$g-color-facebook-dark-v1: darken($g-color-facebook, 5%) !default;
$g-color-facebook-dark-v2: darken($g-color-facebook, 10%) !default;
$g-color-facebook-light-v1: lighten($g-color-facebook, 5%) !default;

// Twitter Color
$g-color-twitter: #00acee !default;
$g-color-twitter-dark-v1: darken($g-color-twitter, 5%) !default;
$g-color-twitter-dark-v2: darken($g-color-twitter, 10%) !default;
$g-color-twitter-light-v1: lighten($g-color-twitter, 5%) !default;

// Skype Color
$g-color-skype: #00aff0 !default;
$g-color-skype-dark-v1: darken($g-color-skype, 5%) !default;
$g-color-skype-dark-v2: darken($g-color-skype, 10%) !default;
$g-color-skype-light-v1: lighten($g-color-skype, 5%) !default;

// Pinterest Color
$g-color-pinterest: #c8232c !default;
$g-color-pinterest-dark-v1: darken($g-color-pinterest, 5%) !default;
$g-color-pinterest-dark-v2: darken($g-color-pinterest, 10%) !default;
$g-color-pinterest-light-v1: lighten($g-color-pinterest, 5%) !default;

// Vine Color
$g-color-vine: #00bf8f !default;
$g-color-vine-dark-v1: darken($g-color-vine, 5%) !default;
$g-color-vine-dark-v2: darken($g-color-vine, 10%) !default;
$g-color-vine-light-v1: lighten($g-color-vine, 5%) !default;

// Youtube Color
$g-color-youtube: #c4302b !default;
$g-color-youtube-dark-v1: darken($g-color-youtube, 5%) !default;
$g-color-youtube-dark-v2: darken($g-color-youtube, 10%) !default;
$g-color-youtube-light-v1: lighten($g-color-youtube, 5%) !default;

// Google Plus Color
$g-color-google-plus: #dd4b39 !default;
$g-color-google-plus-dark-v1: darken($g-color-google-plus, 5%) !default;
$g-color-google-plus-dark-v2: darken($g-color-google-plus, 10%) !default;
$g-color-google-plus-light-v1: lighten($g-color-google-plus, 5%) !default;

// Dribbble Color
$g-color-dribbble: #ea4c89 !default;
$g-color-dribbble-dark-v1: darken($g-color-dribbble, 5%) !default;
$g-color-dribbble-dark-v2: darken($g-color-dribbble, 10%) !default;
$g-color-dribbble-light-v1: lighten($g-color-dribbble, 5%) !default;

// VK Color
$g-color-vk: #2b587a !default;
$g-color-vk-dark-v1: darken($g-color-vk, 5%) !default;
$g-color-vk-dark-v2: darken($g-color-vk, 10%) !default;
$g-color-vk-light-v1: lighten($g-color-vk, 5%) !default;

// Linkedin Color
$g-color-linkedin: #0e76a8 !default;
$g-color-linkedin-dark-v1: darken($g-color-linkedin, 5%) !default;
$g-color-linkedin-dark-v2: darken($g-color-linkedin, 10%) !default;
$g-color-linkedin-light-v1: lighten($g-color-linkedin, 5%) !default;

// Instagram Color
$g-color-instagram: #3f729b !default;
$g-color-instagram-dark-v1: darken($g-color-instagram, 5%) !default;
$g-color-instagram-dark-v2: darken($g-color-instagram, 10%) !default;
$g-color-instagram-light-v1: lighten($g-color-instagram, 5%) !default;
